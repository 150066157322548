import React from "react"

export default function Vignette() {
  const vignetteStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100svw",
    height: "100svh",
    background: "radial-gradient(transparent, black)",
    opacity: "0.66",
    zIndex: "15",
  }

  return <div style={vignetteStyle} />
}
