// import * as serviceWorker from "./serviceWorker"

import React from "react"
import ReactDOM from "react-dom"
import posthog from "posthog-js"
import App from "./App"

import "./index.sass"

const ph = posthog.init("phc_mJb9F9fMQtX5oXDqPDIEx7PH3hEpyCKJj8X4a2NeXWz", {
	api_host: "https://app.posthog.com",
})

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root"),
)

// serviceWorker.unregister()
