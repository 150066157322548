import React, { useState } from "react"

import Masthead from "./components/Masthead"
import Me from "./components/Me"
import Player from "./components/Player"
import Scenery from "./components/Scenery"
import Footer from "./components/Footer"

import "./variables.css"
import "./App.sass"

function App() {
	const [isWalking, setWalking] = useState(false)

	return (
		<div className="App">
			<Scenery isWalking={isWalking}>
				<Masthead>slowpocalypse</Masthead>

				<Me isWalking={isWalking} />

				<Player
					onPlay={() => setWalking(true)}
					onStop={() => setWalking(false)}
				/>
			</Scenery>
		</div>
	)
}

export default App
