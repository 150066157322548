import React, { useState, useEffect, useRef } from "react"

import nukeImg from "../assets/nuke_2024.jpg"
import tornadoImg from "../assets/tornado_2024.jpg"
import fireImg from "../assets/forest_fire_2024.jpg"
import gazaImg from "../assets/gaza_2024.jpg"
import ukraineImg from "../assets/ukraine_2024.jpg"
// import locustImg from "../assets/locusts_2024.jpg"

import Vignette from "./Vignette"

import "./Scenery.sass"

const DELAY = 1000 * 15 * 1

const IMAGES = [nukeImg, fireImg, tornadoImg, ukraineImg, gazaImg]

function Scenery({ isWalking, children }) {
  const [imageIndex, setImageIndex] = useState(-1)

  const rotateTimeout = useRef()
  const elementRef = useRef()

  function handleRotation() {
    const images =
      elementRef.current.querySelectorAll("img:not(.current)") || []

    images.forEach((img) => {
      img.style.transition = null
      img.style.transform = null
    })

    const currentImage = elementRef.current.querySelector("img.current")

    if (currentImage) {
      currentImage.style.transition = null
    }

    setImageIndex((value) => {
      return (value + 1) % IMAGES.length
    })
  }

  useEffect(() => {
    const images = elementRef.current.querySelectorAll("img") || []

    if (!isWalking) {
      images.forEach((img) => {
        const computedStyle = window.getComputedStyle(img)
        const transformValue = computedStyle.getPropertyValue("transform")
        if (transformValue !== "none") {
          img.style.transition = "none"
          img.style.transform = transformValue
        }
      })

      return
    }

    clearInterval(rotateTimeout.current)
    rotateTimeout.current = setInterval(handleRotation, DELAY)

    setImageIndex((value) => {
      return (value + 1) % IMAGES.length
    })
  }, [isWalking])

  useEffect(() => {
    rotateTimeout.current = setInterval(handleRotation, DELAY)

    return () => clearInterval(rotateTimeout.current)
  }, [])

  const handleFirstLoad = () => {
    if (imageIndex === -1) {
      setImageIndex(0)
    }
  }

  const images = IMAGES.map((img, i) => {
    const className =
      i === imageIndex
        ? "current"
        : i - 1 === imageIndex || (imageIndex === IMAGES.length - 1 && i === 0)
        ? "next"
        : (i + 1) % IMAGES.length === imageIndex
        ? "previous"
        : "hidden"

    const isHidden = className === "hidden"

    return isHidden ? (
      <div key={`image-${i}-${!isHidden}`} />
    ) : (
      <img
        src={img}
        alt={`Slowpocalyptic background #${i + 1}`}
        className={className}
        key={`image-${i}-${!isHidden}`}
        onLoad={i === 0 ? handleFirstLoad : void 0}
      />
    )
  })

  return (
    <div className={`Scenery ${isWalking ? "isWalking" : ""}`} ref={elementRef}>
      <div className="noise" />

      <div className="foreground">{children}</div>

      <Vignette />

      <div className="background">{images}</div>
    </div>
  )
}

export default Scenery
