import React, { useState } from "react"

import meImg from "../assets/prompt_engineer_2024_2.png"

import "./Me.sass"

function Me({ isWalking }) {
  const [isLoaded, setLoaded] = useState(false)

  const onLoad = () => {
    setLoaded(true)
  }

  return (
    <div className={`Me ${isWalking ? "isWalking" : ""}`}>
      <div className="container">
        <div className="eye left" />
        <div className="eye right" />
        <img
          src={meImg}
          className={!isLoaded ? "loading" : "loaded"}
          onLoad={onLoad}
          alt="Prompt Engineer"
        />
      </div>
    </div>
  )
}

export default Me
